import React from 'react';
import { URLS } from '../urls.js';

const ProductSystemItemKabel = (params) => {

    const title = params.title || "";
    const imageUrl = URLS['BACKEND'] + params.imageUrl || "";
    const onClick = params.onClick || (() => {});
    const productSystemId = params.productSystemId || 0;
    const item = params.item || {};
    const isSelected = params.isSelected;
    console.log(item);

    return (
      <tr className="card" onClick={ () => onClick() }>
        <td>{ item.penetrations } </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
    </tr>
    )
}


export default ProductSystemItemKabel;