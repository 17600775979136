import React from 'react';

const DataContext = React.createContext({
        custom_item_35_tec_assesment: {
            initial: "eta",
            current: "eta",
            freezed: false
        },
        custom_item_02_type: {
            initial: "",
            current: ""
        },
        item_15_structural_asset: {
            initial: "3.407.31",
            current: "3.407.31"
        },
        item_10_name: {
            initial: "IV-100-Leca",
            current: "IV-100-Leca"
        },
        item_11_material_name: {
            initial: "N_LECA blockwork",
            current: "N_LECA blockwork"
        },
        item_12_material_category: {
            initial: "Concrete",
            current: "Concrete"
        },
        item_13_material_class: {
            initial: "Concrete",
            current: "Concrete"
        }, 
        item_custom_material: {
            initial: "drywall",
            current: "drywall"
        },
        custom_item_07_length: {
            initial: "70",
            current: "70"
        },
        custom_item_14_fire_rating: {
            initial: "30",
            current: "30"
        },
        item_01_pfv_uniqueID: {
            initial: "607e7a34-Ocfb-490d-934d-cccef4fe9bec-000609b9",
            current: "607e7a34-Ocfb-490d-934d-cccef4fe9bec-000609b9"
        },
        item_custom_shape: {
            initial: "round",
            current: "round"
        },
        item_04_diameter: {
            initial: "23",
            current: "23"
        },
        item_05_height: {
            initial: "23",
            current: "23"
        },
        item_06_width: {
            initial: "43",
            current: "43"
        },
        item_07_length: {
            initial: "150",
            current: "150"
        },
        item_17_MEP_element_uniqueID: {
            initial: "607e7a34-Ocfb-490d-934d-cccef4fe9bec-000609b9",
            current: "607e7a34-Ocfb-490d-934d-cccef4fe9bec-000609b9"
        },
        item_18_category: {
            initial: "",
            current: ""
        },
        item_19_description: {
            initial: "",
            current: ""
        },
        item_20_material_code: {
            initial: "",
            current: ""
        },
        item_custom_mep_shape: {
            initial: "round",
            current: "round"
        },
        item_24_diameter: {
            initial: "25",
            current: "25"
        },
        item_24_outer_diameter: {
            initial: "30",
            current: "30"
        },
        item_custom_annular_gap_width: {
            initial: "150",
            current: "150"
        },
        item_mep_elements_size_height: {
            initial: "23",
            current: "23"
        },
        item_23_width: {
            initial: "43",
            current: "43"
        },
        item_25_wall_thickness: {
            initial: "43",
            current: "43"
        },
        item_26_penetration_angle: {
            initial: "45",
            current: "45"
        },
        item_custom_penetrating_service_type: {
            initial: "cable_route",
            current: "cable_route"
        },
        item_custom_no_penetrating_services: {
            initial: "none",
            current: "none"
        },
        item_pipe_end_configuration: {
            initial: "",
            current: ""
        },
        item_28_insulation_uid: {
            initial: "607e7a34-Ocfb-490d-934d-cccef4fe9bec-000609b9",
            current: "607e7a34-Ocfb-490d-934d-cccef4fe9bec-000609b9",
        },
        item_custom_given_insulation_material: {
            initial: "none",
            current: "none"
        },
        item_34_given_insulation_thickness: {
            initial: "50",
            current: "50",
        },
        item_custom_given_insulation_configuration: {
            initial: "",
            current: ""
        },
        item_09_construction_element_uid: {
            initial: "607e7a34-Ocfb-490d-934d-cccef4fe9bec-000609b9",
            current: "607e7a34-Ocfb-490d-934d-cccef4fe9bec-000609b9",
        },
        item_custom_mep_penetration_id: {
            initial: "",
            current: ""
        },
        item_custom_mep_material_id: {
            initial: "",
            current: ""
        },
        item_custom_insulation_product_id: {
            initial: "",
            current: ""
        },       


        custom_item_direction_x: {
            initial: "",
            current: ""
        },
        custom_item_direction_y: {
            initial: "",
            current: ""
        },
        custom_item_direction_z: {
            initial: "",
            current: ""
        },


        custom_item_location_x: {
            initial: "",
            current: ""
        },
        custom_item_location_y: {
            initial: "",
            current: ""
        },
        custom_item_location_z: {
            initial: "",
            current: ""
        },


        custom_item_mep_direction_x: {
            initial: "",
            current: ""
        },
        custom_item_mep_direction_y: {
            initial: "",
            current: ""
        },
        custom_item_mep_direction_z: {
            initial: "",
            current: ""
        },


        custom_item_mep_location_x: {
            initial: "",
            current: ""
        },
        custom_item_mep_location_y: {
            initial: "s",
            current: ""
        },
        custom_item_mep_location_z: {
            initial: "",
            current: ""
        }
})

export default DataContext;

