import React from 'react';
import { URLS } from '../urls.js';

const ProductSystemCard = (params) => {

    const title = params.title || "";
    const imageUrl = URLS['BACKEND'] + params.imageUrl || "";
    const onClick = params.onClick || (() => {});
    const isSelected = params.isSelected;
    const highlightStatus = (isSelected) ? "card-border highlight" : "card-border";

    return (
      <div className={ highlightStatus }>
        <div className="card" onClick={ () => onClick() }>
          <img src={imageUrl} alt={title} />
          <div className="card-body">
            <div className="card-title">{title}</div>
        </div>
      </div>
    </div>
    )
}


export default ProductSystemCard;