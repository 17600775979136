import './App.css';
import Wizard from './Components/Wizard/Wizard.js';
import WizardProvider from './store/WizardProvider.js'
import DataProvider from './store/DataProvider';
import InitDataProvider from './store/InitDataProvider';
import ProductSystemProvider from './store/ProductSystemProvider';
import ProductSystemItemProvider from './store/ProductSystemItemProvider';
import SessionProvider from './store/SessionProvider';

function App() {
  return (
    <div className="App">
      <WizardProvider>
        <ProductSystemProvider> 
        <ProductSystemItemProvider> 
          <DataProvider>
            <SessionProvider>
            <InitDataProvider>
              <Wizard />
            </InitDataProvider>
            </SessionProvider>
          </DataProvider>
          </ProductSystemItemProvider>
        </ProductSystemProvider>
      </WizardProvider>
    </div>
  );
}

export default App;
