import { useReducer } from 'react';

import ProductSystemContext from './product-system-context.js';

const defaulProductSystemsState = {
  productSystems: [],
  productSystem: {}
};

const productSystemReducer = (state, action) => {
  if (action.type === 'SET_PRODUCTS'){
    const updatedData = action.data
    return {
      ...state,
      productSystems: updatedData
    }
  }
  if (action.type === 'SET_PRODUCTSYSTEM'){
    const updatedData = action.data
    return {
      ...state,
      productSystem: updatedData
    }
  }

  return defaulProductSystemsState;
};



const ProductSystemProvider = props => {
    const [wizardState, dispatchWizardAction] = useReducer(productSystemReducer, defaulProductSystemsState);

    const clear = () => {
      dispatchWizardAction({type: 'SET_PRODUCTSYSTEM', data: {}});
    }

    const setProductSystems = (data) => {
      const arr = (data !== undefined && data['product_systems'] !== undefined)? data['product_systems'] : [];
      dispatchWizardAction({type: 'SET_PRODUCTS', data: arr});
    };
    const setProductSystem = (data) => {
      dispatchWizardAction({type: 'SET_PRODUCTSYSTEM', data: data});
    };

    const ProductSystemContextValue = {
      productSystems: wizardState.productSystems,
      set_product_systems: setProductSystems,
      set_product_system: setProductSystem,
      productSystem: wizardState.productSystem,
      clear: clear,

    };

    return <ProductSystemContext.Provider value={ProductSystemContextValue}>
        {props.children}
    </ProductSystemContext.Provider>
};

export default ProductSystemProvider;