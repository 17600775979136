import { axiosTracker } from '../axios-setup';
import { axiosProtected } from '../axios-setup';
import { URLS } from '../urls';

export const pushEvent = (data) => {
    const url = '/api/revit/events/'
    const event_data = {
                         event: {
                           action: data['action'],
                           data: data,
                         }
                       }

    return dispatch => {
      if (URLS['BACKEND'] !== 'https://productselector.de') {
        return axiosTracker.post( url, event_data )
      } else {
        return Promise.reject(new Error('fail')).then(function(error) {
  // nicht aufgerufen
}, function(error) {
  //console.log(error); // Stacktrace
});
      }
    };

};



export const pushProtectedEvent = (data) => {
    const url = '/api/revit/events/internal'
    const event_data = {
                         event: {
                           action: data['action'],
                           data: data,
                         }
                       }

    return dispatch => {
        if (URLS['BACKEND'] !== 'https://productselector.de') {
          return axiosProtected.post( url, event_data )
        } else {
          return Promise.reject(new Error('fail')).then(function(error) {
  // nicht aufgerufen
}, function(error) {
  console.log(error); // Stacktrace
});
        }
    };

};

export const eventFirstVisit = (key, q) => {
  if (q === 'k1h2') {
    return dispatch => dispatch( pushEvent({action: 'ref_produktmappe', id: key, value: q}))
  }
  if (q === '2rhde2') {
    return dispatch => dispatch( pushEvent({action: 'ref_website', id: key, value: q}))
  }
}

export const fetchPluginInitalData = (json) => {
  return pushEvent({action: 'get_plugin_data', json: json})
}

export const sendPluginResultData = (json) => {
  return pushEvent({action: 'return_data_to_plugin', json: json})
}