import React from 'react';
import { useContext, useEffect, useState } from 'react';
import WizardContext from '../../../../store/wizard-context.js';
import DataContext from '../../../../store/data-context.js';
import InitDataContext from '../../../../store/init-data-context.js';
import ProductSystemContext from '../../../../store/product-system-context.js';
import ProductSystemItemContext from '../../../../store/product-system-item-context.js';
import './WizardStepReadjustment.css';
import Footer from '../../../Footer.js';
import DataButton from '../../../DataButton.js';
import { URLS } from '../../../../urls.js';
import * as utils from '../../../../utils.js';


const WizardStepReadjustment = () => {
    const wizardCtx = useContext(WizardContext);
    const dataCtx = useContext(DataContext);
    const initDataCtx = useContext(InitDataContext);
    const productSystemCtx = useContext(ProductSystemContext);
    const productSystemItemCtx = useContext(ProductSystemItemContext);
    const [buttonState, setButtonState] = useState('idle');

    const [billOfMaterial, setBillOfMaterial] = useState("");
    const [billOfMaterialSent, setBillOfMaterialSent] = useState(false); 

    const [anzTreffer, setAnzTreffer] = useState([]);

    // selectboxen mep element material und brand
    const [materials, setMaterials] = useState([]);
    const [brands, setBrands] = useState([]);

    // selectboxen insulation products
    const [insulationProducts, setInsulationProducts] = useState([]);

    const [uploading, setUploading] = useState(false);
    const [uploaded, setUploaded] = useState(false);

    useEffect(() => {
        if (wizardCtx.currentStep === 'readjustment') {
          const params = new URLSearchParams({ 
            item_35_tec_assesment: dataCtx.custom_item_35_tec_assesment.current,
            item_02_type: dataCtx.custom_item_02_type.current,
            item_14_fire_rating: dataCtx.custom_item_14_fire_rating.current,
            item_pipe_end_configuration: dataCtx.item_pipe_end_configuration.current,
            item_custom_penetrating_service_type: dataCtx.item_custom_penetrating_service_type.current,
            item_24_diameter: dataCtx.item_24_diameter.current,
            item_24_outer_diameter: dataCtx.item_24_outer_diameter.current,
            item_04_diameter: dataCtx.item_04_diameter.current,
            custom_item_07_length: dataCtx.custom_item_07_length.current,
            item_custom_material: dataCtx.item_custom_material.current,
            item_custom_given_insulation_material: dataCtx.item_custom_given_insulation_material.current,
            item_25_wall_thickness: dataCtx.item_25_wall_thickness.current,
            item_26_penetration_angle: dataCtx.item_26_penetration_angle.current,
            item_custom_no_penetrating_services: dataCtx.item_custom_no_penetrating_services.current,
            item_34_given_insulation_thickness: dataCtx.item_34_given_insulation_thickness.current,
            item_custom_given_insulation_configuration: dataCtx.item_custom_given_insulation_configuration.current,
            mep_penetration_id: dataCtx.item_custom_mep_penetration_id.current,
            mep_material_id: dataCtx.item_custom_mep_material_id.current,
            insulation_product_id: dataCtx.item_custom_insulation_product_id.current

        });
            fetch(URLS['BACKEND'] + `/api/revit/fetch_product_systems?${params}`)
            .then(response => response.json())
            .then(data => { handleUpload(data); })
            .then(data => {   productSystemItemCtx.clear(); } )
            .catch(
                error => {
                    setAnzTreffer(0);
                    console.log('Fehler:', error)
                }
            );
        }
      }, [dataCtx.custom_item_35_tec_assesment, 
        dataCtx.custom_item_02_type,
        dataCtx.custom_item_14_fire_rating,
        dataCtx.item_pipe_end_configuration,
        dataCtx.item_custom_penetrating_service_type,
        dataCtx.item_24_diameter,
        dataCtx.item_24_outer_diameter,
        dataCtx.item_04_diameter,
        dataCtx.custom_item_07_length,
        dataCtx.item_custom_material,
        dataCtx.item_custom_given_insulation_material,
        dataCtx.item_25_wall_thickness,
        dataCtx.item_26_penetration_angle,
        dataCtx.item_custom_given_insulation_configuration,
        dataCtx.item_custom_no_penetrating_services,
        dataCtx.item_34_given_insulation_thickness,
        wizardCtx.currentStep,
        dataCtx.item_custom_mep_penetration_id,
        dataCtx.item_custom_mep_material_id,
        dataCtx.item_custom_insulation_product_id
    ]);

   
    useEffect(() => {
        if (wizardCtx.currentStep === 'readjustment') {
            fetch(URLS['BACKEND'] + `/api/revit/fetch_insulation_products`)
            .then(response => response.json())
            .then(data => setInsulationProducts(data))
            .catch(
                error => {
                    console.log('Fehler:', error)
                }
            );
        }
        }
        , [wizardCtx.currentStep]);

    useEffect(() => {
        if (wizardCtx.currentStep === 'readjustment') {
            fetch(URLS['BACKEND'] + `/api/revit/fetch_materials`)
            .then(response => response.json())
            .then(data => setMaterials(data))
            .catch(
                error => {
                    console.log('Fehler:', error)
                }
            );
        }
        }
        , [wizardCtx.currentStep]);

    useEffect(() => {
        if (wizardCtx.currentStep === 'readjustment') {
            fetch(URLS['BACKEND'] + `/api/revit/fetch_brands`)
            .then(response => response.json())
            .then(data => setBrands(data))
            .catch(
                error => {
                    console.log('Fehler:', error)
                }
            );
        }
        }
        , [wizardCtx.currentStep]);

    useEffect(() => {
        if (wizardCtx.currentStep === 'readjustment') {
          if (productSystemCtx.productSystems !== undefined) {
            setAnzTreffer(productSystemCtx.productSystems.length);
            setButtonState('success');
          } 
        }
      }, [productSystemCtx.productSystems, wizardCtx.currentStep]);
      

    useEffect(() => {
        if (wizardCtx.currentStep === 'readjustment' && billOfMaterial === "" && billOfMaterialSent === false) {
            fetch(URLS['BACKEND'] + `/api/revit/calculate_bill_of_material`)
            .then(response => response.json())
            .then(data => {
                setBillOfMaterial(data.text);

                    if (window.eoapi !== undefined) {
                      window.eoapi.extInvoke("MCPluginRevitSharedParameterFile", data.text);
                      setBillOfMaterialSent(true);
                    }

           })
            .catch(
                error => {
                    //setAnzTreffer(0);
                }
            );
        }
  
    }, [wizardCtx.currentStep]);
        
        
    if (wizardCtx.currentStep !== 'readjustment'){
      return null;
    }


    const selectBrand = (event) => {
        console.log("selectBrand: ", event.target.value);

        set_data_value("item_custom_mep_penetration_id", event.target.value);
    }

    const selectMaterial = (event) => {
        console.log("selectMaterial: ", event.target.value);
        set_data_value("item_custom_mep_material_id", event.target.value);
    }

    const selectInsulationProduct = (event) => {
        console.log("selectInsulationProduct: ", event.target.value);
        set_data_value("item_custom_insulation_product_id", event.target.value);
    }

    const matchMediumType = (item_custom_penetrating_service_type) => {
        let filter_by_medium = null;
        switch (item_custom_penetrating_service_type) {
            case 'alubond_pipes':
            case 'a':
                filter_by_medium = "a"; // Mehrschichtrohre (Alubond)
                break;
            case 'comb_pipes':
            case 'r':
                filter_by_medium = 'r'; // Rohre brennbar
                break;
            case 'non_comb_pipes':
            case 'rn':
                filter_by_medium = 'rn'; // Rohre nicht brennbar
                break;
            case 'single_cable':
            case 'ke':
                filter_by_medium = 'ke'; // Einzelkabel
                break;
            case 'cable_bundle':
            case 'kb':
                filter_by_medium = 'kb'; // Kabelbündel
                break;
            case 'electro_inst_pipes':
            case 'e':
                filter_by_medium = 'e'; // EIR/Flexrohre
                break;
            case 'cable_route':
            case 'kt':
                filter_by_medium = 'kt'; // Kabeltrasse
                break;
            case 'lueftung_rund':
            case 'lue_r':
                filter_by_medium = 'lue_r'; // Lüftung rund
                break;
            case 'lueftung_eckig':
            case 'lue_e':
                filter_by_medium = 'lue_e'; // Lüftung eckig
                break;
            default:
                filter_by_medium = null;
                break;
        }
        return filter_by_medium;
    }

    const getFilteredBrands = (item_custom_penetrating_service_type) => {

        var filtered = null;
        if (item_custom_penetrating_service_type === "not_specified" || item_custom_penetrating_service_type === "") {
            filtered = brands;
        } else {
            let filter_by_medium = matchMediumType(item_custom_penetrating_service_type);
            filtered = brands.filter(brand => brand.medium_type === filter_by_medium);
        }
        return [...new Set(filtered.map(brand => brand.productname))].map(brandName => {
            const brand = filtered.find(mat => mat.productname === brandName);
            return { productname: brand.productname, penetration_id: brand.penetration_id };
        });
    }



    const getFilteredMaterials = (item_custom_penetrating_service_type) => {
        var filtered = null;
        if (item_custom_penetrating_service_type === "not_specified" || item_custom_penetrating_service_type === "") {
            filtered = materials;
        } else {
            let filter_by_medium = matchMediumType(item_custom_penetrating_service_type);
            filtered = materials.filter(material => material.medium_type === filter_by_medium)
        }
        return [...new Set(filtered.map(material => material.material_name))].map(materialName => {
            const material = filtered.find(mat => mat.material_name === materialName);
            return { material_name: material.material_name, material_id: material.material_id };
        });;
    }

    const getFilteredInsulationProducts = (item_custom_given_insulation_material) => {
        console.log(insulationProducts);
        var filtered = null;
        if (item_custom_given_insulation_material === "not_specified" || item_custom_given_insulation_material === "") {
            filtered = insulationProducts;
        } else {
            filtered = insulationProducts.filter(product => product.materials.includes(item_custom_given_insulation_material))
        }
        return [...new Set(filtered.map(material => material.productname))].map(materialName => {
            const product = filtered.find(mat => mat.productname === materialName);
            return { productname: product.productname, insulation_id: product.insulation_id };
        });;
    }

    const goToIntro = () => wizardCtx.goToIntro();
    const goToProductSystems = () => {
        productSystemItemCtx.clear();
        productSystemCtx.clear();
      return  wizardCtx.goToProductSystems();
    }

    const set_data_value = async (name, value) => {
        return dataCtx.set_data_value(name, value);
    }



    const handleUpload = (data) => {
        setUploading(true);
        setUploaded(false);
        
        setTimeout(() => {
          setUploading(false);
          setUploaded(true);
          productSystemCtx.set_product_systems(data);
        }, 500);
      };

    return (
        <span>
            <header>
            Matching of data for Hensel product selection 
            </header>
            <hr />

            <div className="container">
            <div className="table-title">General Requirements</div>
            <table className="page2">
                <tbody>
                    <tr>
                        <td className="ready">Technical Assessment:</td>
                        <td>{ initDataCtx.item_35_tec_assesment }</td>
                        <td>
                            <DataButton 
                            title={ utils.toText('abg')} 
                            value="abg" 
                            freezed={dataCtx.custom_item_35_tec_assesment.freezed} 
                            currentGroupValue={dataCtx.custom_item_35_tec_assesment.current} 
                            dataKey="custom_item_35_tec_assesment" 
                            onSelect={ set_data_value } />
                            <DataButton 
                            title={ utils.toText('eta')} 
                            value="eta" 
                            freezed={dataCtx.custom_item_35_tec_assesment.freezed} 
                            currentGroupValue={dataCtx.custom_item_35_tec_assesment.current} 
                            dataKey="custom_item_35_tec_assesment" 
                            onSelect={ set_data_value } />
                        </td>
                    </tr>
                </tbody>
            </table>

            <div className="table-title">Construction Element</div>
            <table className="page2">
                <tbody>
                { (true)? null :
                <tr>
                    <td>Construction Element UniqueID:</td>
                    <td>{initDataCtx.item_09_construction_element_uid}</td>
                    <td className="static_value">{dataCtx.item_09_construction_element_uid.current}</td>
                </tr>
                }
                <tr>
                    <td className="ready">Construction Element:</td>
                    <td>{ initDataCtx.item_02_type }</td>
                    <td>
                        <DataButton 
                          title={ utils.toText('wall') }
                          value="wall"
                          freezed={dataCtx.custom_item_02_type.freezed} 
                          currentGroupValue={dataCtx.custom_item_02_type.current} 
                          dataKey="custom_item_02_type" 
                          onSelect={ set_data_value } />
                        <DataButton 
                          title={ utils.toText('floor')}
                          value="floor"
                          freezed={dataCtx.custom_item_02_type.freezed} 
                          currentGroupValue={dataCtx.custom_item_02_type.current} 
                          dataKey="custom_item_02_type" 
                          onSelect={ set_data_value } />
                    </td>
                </tr>
                <tr>
                    <td>Density [kg/m3]:</td>
                    <td>{initDataCtx.item_15_structural_asset}</td>
                                    <td className="static_value">{initDataCtx.item_15_structural_asset}</td>
                            </tr>
                            <tr>
                                    <td className="ready">Material:</td>
                                    <td>{ initDataCtx.item_10_name }</td>
                                    <td rowSpan="4">
                                            <DataButton 
                                                title={ utils.toText('massive') }
                                                value="massive" 
                                                currentGroupValue={dataCtx.item_custom_material.current} 
                                                dataKey="item_custom_material" 
                                                onSelect={ set_data_value } />
                                            <DataButton 
                                                title={ utils.toText('drywall') }
                                                value="drywall" 
                                                currentGroupValue={dataCtx.item_custom_material.current} 
                                                dataKey="item_custom_material" 
                                                onSelect={ set_data_value } />
                                            <DataButton 
                                                title={ utils.toText('wood') }
                                                value="wood" 
                                                currentGroupValue={dataCtx.item_custom_material.current} 
                                                dataKey="item_custom_material" 
                                                onSelect={ set_data_value } />
                                    </td>
                            </tr>
                            <tr>
                                    <td></td>
                                    <td>{ initDataCtx.item_11_material_name }</td>
                            </tr>
                            <tr>
                                    <td></td>
                                    <td>{ initDataCtx.item_12_material_category }</td>
                            </tr>
                            <tr>
                                    <td></td>
                                    <td>{ initDataCtx.item_13_material_class }</td>
                            </tr>
                            <tr>
                                    <td className="ready">Thickness [mm]</td>
                                    <td>{ initDataCtx.item_07_length }</td>
                                    <td>
                                            <DataButton 
                                                title={ utils.toText('thickness_70') }
                                                value="70" 
                                                currentGroupValue={dataCtx.custom_item_07_length.current} 
                                                dataKey="custom_item_07_length" /> 
                                            <DataButton 
                                                title={ utils.toText('thickness_90') }
                                                value="90" 
                                                currentGroupValue={dataCtx.custom_item_07_length.current} 
                                                dataKey="custom_item_07_length" />
                                            <DataButton 
                                                title={ utils.toText('thickness_100') }
                                                value="100" 
                                                currentGroupValue={dataCtx.custom_item_07_length.current} 
                                                dataKey="custom_item_07_length" /><br />
                                            <DataButton 
                                                title={ utils.toText('thickness_150') }
                                                value="150" 
                                                currentGroupValue={dataCtx.custom_item_07_length.current} 
                                                dataKey="custom_item_07_length" />
                                            <DataButton 
                                                title={ utils.toText('thickness_200') }
                                                value="200" 
                                                currentGroupValue={dataCtx.custom_item_07_length.current} 
                                                dataKey="custom_item_07_length" /><br />

                                    </td>
                            </tr>
                            <tr>
                                    <td className="ready">Required fire resistance period [min]</td>
                                    <td>{ initDataCtx.item_14_fire_rating }</td>
                                    <td>
                                            <DataButton title="30 min" value="30" currentGroupValue={dataCtx.custom_item_14_fire_rating.current} initialValue={dataCtx.custom_item_14_fire_rating.initial} dataKey="custom_item_14_fire_rating" onSelect={ set_data_value } />
                                            <DataButton title="60 min" value="60" currentGroupValue={dataCtx.custom_item_14_fire_rating.current} initialValue={dataCtx.custom_item_14_fire_rating.initial} dataKey="custom_item_14_fire_rating" onSelect={ set_data_value } />
                                            <DataButton title="90 min" value="90" currentGroupValue={dataCtx.custom_item_14_fire_rating.current} initialValue={dataCtx.custom_item_14_fire_rating.initial} dataKey="custom_item_14_fire_rating"  onSelect={ set_data_value } /><br />
                                            <DataButton title="120 min" value="120" currentGroupValue={dataCtx.custom_item_14_fire_rating.current} initialValue={dataCtx.custom_item_14_fire_rating.initial} dataKey="custom_item_14_fire_rating" onSelect={ set_data_value } />
                                            <DataButton title="180 min" value="180" currentGroupValue={dataCtx.custom_item_14_fire_rating.current} initialValue={dataCtx.custom_item_14_fire_rating.initial} dataKey="custom_item_14_fire_rating" onSelect={ set_data_value } />
                                            <DataButton title="240 min" value="240" currentGroupValue={dataCtx.custom_item_14_fire_rating.current} initialValue={dataCtx.custom_item_14_fire_rating.initial} dataKey="custom_item_14_fire_rating" onSelect={ set_data_value } />
                                    </td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <div className="table-title">Provision for Void (PfV)</div>
            <table className="page2">
                <tbody>
                    { (true)? null :
                    <tr>
                        <td>UniqueID:</td>
                        <td>{initDataCtx.item_01_pfv_uniqueID}</td>
                        <td className="static_value">{dataCtx.item_01_pfv_uniqueID.current}</td>
                    </tr>
        }
                    <tr>
                        <td>Shape:</td>
                        <td></td>
                        <td>
                            <DataButton title="round" value="round" currentGroupValue={dataCtx.item_custom_shape.current} dataKey="item_custom_shape" /> 
                            <DataButton title="rectangle" value="rectangle" currentGroupValue={dataCtx.item_custom_shape.current} dataKey="item_custom_shape" />
                        </td>
                    </tr>
                    <tr>
                        <td>Diameter [mm]:</td>
                        <td>{initDataCtx.item_04_diameter}</td>
                        <td className="static_value">{dataCtx.item_04_diameter.current}</td>
                    </tr>
                    <tr>
                        <td>Height [mm] x Width [mm]:</td>
                        <td>{(dataCtx.item_custom_shape.current === "round")? "n.a." : dataCtx.item_05_height.current + "x" + dataCtx.item_06_width.current }</td>
                        <td className="static_value">{(dataCtx.item_custom_shape.current === "round")? "n.a." : dataCtx.item_05_height.current + "x" + dataCtx.item_06_width.current }</td>
                    </tr>
                    <tr>
                        <td>Length [mm]:</td>
                        <td>{initDataCtx.item_07_length}</td>
                        <td className="static_value">{initDataCtx.item_07_length}</td>
                    </tr>
                </tbody>
            </table>

            <div className="table-title">MEP Element</div>
            <table className="page2">
                <tbody>
                    { (true)? null :
                    <tr>
                        <td>UniqueID:</td>
                        <td>{initDataCtx.item_17_MEP_element_uniqueID}</td>
                        <td className="static_value">{dataCtx.item_17_MEP_element_uniqueID.current}</td>
                    </tr>
                    }
                    <tr>
                        <td>Shape:</td>
                        <td></td>
                        <td>
                            <DataButton title="round" value="round" currentGroupValue={dataCtx.item_custom_mep_shape.current} dataKey="item_custom_mep_shape" /> 
                            <DataButton title="rectangle" value="rectangle" currentGroupValue={dataCtx.item_custom_mep_shape.current} dataKey="item_custom_mep_shape" />
                        </td>
                    </tr>            
                    <tr>
                        <td className="ready">Diameter [mm]:</td>
                        <td>{initDataCtx.item_24_diameter}</td>
                        <td className="static_value">{dataCtx.item_24_diameter.current}</td>
                    </tr>
                    <tr>
                        <td>Outer Diameter [mm]:</td>
                        <td>{initDataCtx.item_24_outer_diameter}</td>
                        <td className="static_value">{dataCtx.item_24_outer_diameter.current}</td>
                    </tr>
                    <tr>
                        <td>Annular gap width:</td>
                        <td>{initDataCtx.item_custom_annular_gap_width}</td>
                        <td className="static_value">{dataCtx.item_custom_annular_gap_width.current}</td>
                    </tr>
                    <tr>
                        <td>Height [mm] x Width [mm]:</td>
                        <td>{(dataCtx.item_custom_mep_shape.current === "round")? "n.a." : dataCtx.item_mep_elements_size_height.current + "x" + dataCtx.item_23_width.current }</td>
                        <td className="static_value">{(dataCtx.item_custom_mep_shape.current === "round")? "n.a." : dataCtx.item_mep_elements_size_height.current + "x" + dataCtx.item_23_width.current }</td>
                    </tr>
                    <tr>
                        <td className='ready'>Wall Thickness [mm]:</td>
                        <td>{initDataCtx.item_25_wall_thickness}</td>
                        <td className="static_value">{dataCtx.item_25_wall_thickness.current}</td>
                    </tr>
                    <tr>
                        <td className="ready">Penetration angle [deg]:</td>
                        <td>{initDataCtx.item_26_penetration_angle}</td>
                        <td className="static_value">{dataCtx.item_26_penetration_angle.current}</td>
                    </tr>
                    <tr>
                        <td>Penetrating services:</td>
                        <td></td>
                        <td>
                            <DataButton title="Empty Seal" value="empty_seal" currentGroupValue={dataCtx.item_custom_no_penetrating_services.current} initialValue={dataCtx.item_custom_no_penetrating_services.initial} dataKey="item_custom_no_penetrating_services" onSelect={ set_data_value } />
                            <DataButton title="1 MEP Element" value="none" currentGroupValue={dataCtx.item_custom_no_penetrating_services.current} initialValue={dataCtx.item_custom_no_penetrating_services.initial} dataKey="item_custom_no_penetrating_services" onSelect={ set_data_value } />
                        </td>
                    </tr>
                    <tr>
                        <td className="ready">Penetrating service type:</td>
                        <td>{dataCtx.item_18_category.current}<br />{dataCtx.item_19_description.current}<br />{dataCtx.item_20_material_code.current}</td>
                        <td>
                            <DataButton
                                title="Not specified"
                                value="not_specified"
                                widthClass="btn-half-size"
                                currentGroupValue={dataCtx.item_custom_penetrating_service_type.current}
                                initialValue={dataCtx.item_custom_penetrating_service_type.initial}
                                dataKey="item_custom_penetrating_service_type"
                                onSelect={set_data_value}
                            />
                            <DataButton
                                title="Alubond Pipes"
                                value="alubond_pipes"
                                widthClass="btn-half-size"
                                currentGroupValue={dataCtx.item_custom_penetrating_service_type.current}
                                initialValue={dataCtx.item_custom_penetrating_service_type.initial}
                                dataKey="item_custom_penetrating_service_type"
                                onSelect={set_data_value}
                            /><br />

                            <DataButton
                                title="Comb. Pipes"
                                value="comb_pipes"
                                widthClass="btn-half-size"
                                currentGroupValue={dataCtx.item_custom_penetrating_service_type.current}
                                initialValue={dataCtx.item_custom_penetrating_service_type.initial}
                                dataKey="item_custom_penetrating_service_type"
                                onSelect={set_data_value}
                            />
                            <DataButton
                                title="Non-comb. Pipes"
                                value="non_comb_pipes"
                                widthClass="btn-half-size"
                                currentGroupValue={dataCtx.item_custom_penetrating_service_type.current}
                                initialValue={dataCtx.item_custom_penetrating_service_type.initial}
                                dataKey="item_custom_penetrating_service_type"
                                onSelect={set_data_value}
                            /><br />

                            <DataButton
                                title="Single Cable"
                                value="single_cable"
                                widthClass="btn-half-size"
                                currentGroupValue={dataCtx.item_custom_penetrating_service_type.current}
                                initialValue={dataCtx.item_custom_penetrating_service_type.initial}
                                dataKey="item_custom_penetrating_service_type"
                                onSelect={set_data_value}
                            />
                            <DataButton
                                title="Cable Bundle"
                                value="cable_bundle"
                                widthClass="btn-half-size"
                                currentGroupValue={dataCtx.item_custom_penetrating_service_type.current}
                                initialValue={dataCtx.item_custom_penetrating_service_type.initial}
                                dataKey="item_custom_penetrating_service_type"
                                onSelect={set_data_value}
                            /><br />

                            <DataButton
                                title="Electro inst.Pipes"
                                value="electro_inst_pipes"
                                widthClass="btn-half-size"
                                currentGroupValue={dataCtx.item_custom_penetrating_service_type.current}
                                initialValue={dataCtx.item_custom_penetrating_service_type.initial}
                                dataKey="item_custom_penetrating_service_type"
                                onSelect={set_data_value}
                            />
                            <DataButton
                                title="Cable Route"
                                value="cable_route"
                                widthClass="btn-half-size"
                                currentGroupValue={dataCtx.item_custom_penetrating_service_type.current}
                                initialValue={dataCtx.item_custom_penetrating_service_type.initial}
                                dataKey="item_custom_penetrating_service_type"
                                onSelect={set_data_value}
                            /><br />

                            <DataButton
                                title="Ventilation round"
                                value="lueftung_rund"
                                widthClass="btn-half-size"
                                currentGroupValue={dataCtx.item_custom_penetrating_service_type.current}
                                initialValue={dataCtx.item_custom_penetrating_service_type.initial}
                                dataKey="item_custom_penetrating_service_type"
                                onSelect={set_data_value}
                            />
                            <DataButton
                                title="Ventilation angular"
                                value="lueftung_eckig"
                                widthClass="btn-half-size"
                                currentGroupValue={dataCtx.item_custom_penetrating_service_type.current}
                                initialValue={dataCtx.item_custom_penetrating_service_type.initial}
                                dataKey="item_custom_penetrating_service_type"
                                onSelect={set_data_value}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="ready">Material or brand name:</td>
                        <td></td>
                            <td style={{paddingLeft: "20px"}}>
                                <b>Material: </b>
                                {dataCtx.item_custom_no_penetrating_services.current !== "empty_seal" && getFilteredMaterials(dataCtx.item_custom_penetrating_service_type.current).length > 0 ? (
                                    <select 
                                        onChange={selectMaterial}
                                        style={{padding: "6px", marginBottom: "10px"}}
                                        value={dataCtx.item_custom_mep_material_id.current}
                                    >
                                        <option value="">Select Material</option> 
                                        {getFilteredMaterials(dataCtx.item_custom_penetrating_service_type.current)
                                            .sort((a, b) => a.material_name.localeCompare(b.material_name))
                                            .map(material => {
                                                const { material_id, material_name } = material;
                                                return (
                                                    <option key={material_id} value={material_id}>
                                                        {material_name}
                                                    </option>
                                                );
                                            })}
                                    </select>
                                ) : (
                                    <select style={{padding: "6px", marginBottom: "10px"}} disabled>
                                        <option value="">No Selection available</option>
                                    </select>
                                )}
                                <br />
                                <b>Product: </b>
                                {dataCtx.item_custom_no_penetrating_services.current !== "empty_seal" && getFilteredBrands(dataCtx.item_custom_penetrating_service_type.current).length > 0 ? (
                                    <select
                                        onChange={selectBrand}
                                        style={{ padding: "6px" }}
                                        value={dataCtx.item_custom_mep_penetration_id.current}
                                    >
                                        <option value="">Select Product</option>
                                        {getFilteredBrands(dataCtx.item_custom_penetrating_service_type.current)
                                            .sort((a, b) => a.productname.localeCompare(b.productname))
                                            .map((brand) => {
                                                const { penetration_id, productname } = brand;
                                                return (
                                                    <option key={penetration_id} value={penetration_id}>
                                                        {productname}
                                                    </option>
                                                );
                                            })}
                                    </select>
                                ) : (
                                    <select style={{ padding: "6px" }} disabled>
                                        <option value="">No Selection available</option>
                                    </select>
                                )}
                            </td>
                    
                    </tr>

                    <tr>
                        <td className="ready">Pipe-end-configuration:</td>
                        <td></td>
                        <td>
                            <DataButton title="Not specified" value="" currentGroupValue={dataCtx.item_pipe_end_configuration.current} initialValue={dataCtx.item_pipe_end_configuration.initial} dataKey="item_pipe_end_configuration" onSelect={ set_data_value } />
                            <DataButton title="u/u" value="u/u" currentGroupValue={dataCtx.item_pipe_end_configuration.current} initialValue={dataCtx.item_pipe_end_configuration.initial} dataKey="item_pipe_end_configuration" onSelect={ set_data_value } />
                            <DataButton title="u/c" value="u/c" currentGroupValue={dataCtx.item_pipe_end_configuration.current} initialValue={dataCtx.item_pipe_end_configuration.initial} dataKey="item_pipe_end_configuration" onSelect={ set_data_value } />
                            <DataButton title="c/u" value="c/u" currentGroupValue={dataCtx.item_pipe_end_configuration.current} initialValue={dataCtx.item_pipe_end_configuration.initial} dataKey="item_pipe_end_configuration" onSelect={ set_data_value } />
                            <DataButton title="c/c" value="c/c" currentGroupValue={dataCtx.item_pipe_end_configuration.current} initialValue={dataCtx.item_pipe_end_configuration.initial} dataKey="item_pipe_end_configuration" onSelect={ set_data_value } />
                        
                        </td>
                    </tr>
                </tbody>
                          
            </table>



            <div className="table-title">Insulation</div>
            <table className="page2">
                <tbody>
                <tr>
                    <td>UniqueID:</td>
                    <td>{initDataCtx.item_28_insulation_uid}</td>
                    <td className="static_value">{initDataCtx.item_28_insulation_uid}</td>
                </tr>
                <tr>
                    <td className="ready">Given insulation material:</td>
                    <td>{initDataCtx.item_30_description}<br /> {initDataCtx.item_31_national_code} <br />  {initDataCtx.item_32_material_code} <br /> {initDataCtx.item_33_coating_material_code}</td>
                    <td>
                        <DataButton title="Not specified" value="not_specified" currentGroupValue={dataCtx.item_custom_given_insulation_material.current} initialValue={dataCtx.item_custom_given_insulation_material.initial} dataKey="item_custom_given_insulation_material" onSelect={ set_data_value } />
                        <DataButton title="None" value="none" currentGroupValue={dataCtx.item_custom_given_insulation_material.current} initialValue={dataCtx.item_custom_given_insulation_material.initial} dataKey="item_custom_given_insulation_material" onSelect={ set_data_value } />
                        <DataButton title="Flex Elast Foam" value="flex_elast_foam" currentGroupValue={dataCtx.item_custom_given_insulation_material.current} initialValue={dataCtx.item_custom_given_insulation_material.initial} dataKey="item_custom_given_insulation_material" onSelect={ set_data_value } />
                        <DataButton title="Mineral Wool" value="mineral_wool" currentGroupValue={dataCtx.item_custom_given_insulation_material.current} initialValue={dataCtx.item_custom_given_insulation_material.initial} dataKey="item_custom_given_insulation_material" onSelect={ set_data_value } />
                        <DataButton title="PE" value="pe" currentGroupValue={dataCtx.item_custom_given_insulation_material.current} initialValue={dataCtx.item_custom_given_insulation_material.initial} dataKey="item_custom_given_insulation_material" onSelect={ set_data_value } />
                    </td>
                </tr>         
                <tr>
                    <td className="ready">Productname:</td>
                    <td></td>
                        <td style={{paddingLeft: "20px"}}>
                            <b>Specific Product: </b>
                            {dataCtx.item_custom_given_insulation_material.current !== "none" && getFilteredInsulationProducts(dataCtx.item_custom_given_insulation_material.current).length > 0 ? (
                                <select 
                                    onChange={selectInsulationProduct}
                                    style={{padding: "6px", marginBottom: "10px"}}
                                    value={dataCtx.item_custom_insulation_product_id.current}
                                >
                                    <option value="">Select Product</option> 
                                    {getFilteredInsulationProducts(dataCtx.item_custom_given_insulation_material.current)
                                        .sort((a, b) => a.productname.localeCompare(b.productname))
                                        .map(material => {
                                            const { insulation_id, productname } = material;
                                            return (
                                                <option key={insulation_id} value={insulation_id}>
                                                    {productname}
                                                </option>
                                            );
                                        })}
                                </select>
                            ) : (
                                <select style={{padding: "6px", marginBottom: "10px"}} disabled>
                                    <option value="">No Selection available</option>
                                </select>
                            )}
                        </td>
                  
                </tr>     
                <tr>
                    <td className="ready">Given insulation thickness [mm]:</td>
                    <td>{initDataCtx.item_34_given_insulation_thickness}</td>
                    <td className="static_value">{dataCtx.item_34_given_insulation_thickness.current}</td>
                </tr>

                <tr>
                    <td className="ready">Given insulation configuration:</td>
                    <td></td>
                    <td className="static_value">{ (dataCtx.item_custom_given_insulation_configuration.current === "cs")? "CS" : "n.a."}</td>
                </tr>
                </tbody>
                            
            </table>

            <Footer></Footer>
        
            <div className="navbar">
                <button className="back-btn" onClick={goToIntro} style={{textDecoration: "none", color: "black"}} >{"<< Back to transmitted data set"}</button>
                { (anzTreffer > 0)?
                <button className={`continue-btn button ${uploading ? 'progress' : ''}`} onClick={goToProductSystems} style={{textDecoration: "none", color: "black"}}>{"Show " + anzTreffer + " Hensel ProductSystems >>"}</button>
                : <button className={`continue-btn button ${uploading ? 'progress' : ''}`}  style={{textDecoration: "none", color: "grey"}}>{"No Hensel ProductSystems found"}</button> }
            </div>
        </div>
      </span>
    );
}

export default WizardStepReadjustment;

