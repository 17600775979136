import React, { useEffect, useState } from 'react';
import { URLS } from '../urls.js';

const ProductSystemItemDetails = productSystemItemId => {

    const itemId = productSystemItemId.id;
    const [itemDetails, setItemDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchProductSystemItemDetails(itemId);
    }, [itemId]);

    const fetchProductSystemItemDetails = (itemId) => {
        setLoading(true);
        fetch(`${URLS['BACKEND']}/api/revit/fetch_visual_description_item_group?product_system_item_id=${itemId}`)
            .then(response => response.json())
            .then(data => {
                setItemDetails(data["visual_description_item_group"]);
                setLoading(false);
            })
            .catch(error => {
                console.log('Error fetching data', error);
                setError(error);
                setLoading(false);
            });
    };

    if (loading) return <tr><td colSpan="9">Loading...</td></tr>;
    if (error) return <tr><td colSpan="9">Error loading details.</td></tr>;
    if (!itemDetails) return <tr><td colSpan="9">No details available.</td></tr>;

    return (
        <tr>
            <td colSpan="9" style={{backgroundColor: "#98f7b6"}} >
            <div style={{ display: 'flex' }}>
                <div style={{paddingRight: "10px"}}>
                    <img width="300" src={URLS['BACKEND'] + itemDetails.spatial_image_url} />
                </div>
                <div>
                <img width="300" src={URLS['BACKEND'] + itemDetails.cross_section_image_url} />
                </div>
                <div>
                    <ul style={{listStyleType: "none"}}>
                        {itemDetails.legendelements && itemDetails.legendelements.map(element => (
                            <li key={element.id}>
                                {element.position}. {element.text}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            </td>
        </tr>
    );
};

export default ProductSystemItemDetails;
