import React, { useState } from 'react';
import { URLS } from '../urls.js';
import ProductSystemItemDetails from './ProductSystemItemDetails';

const formatRange = (min, max) => {
    if (min === null && max === null) return '';
    if (min === max && max != null) return max.toString().replace('.', ',');
    if (parseInt(min, 10) === 0) return `≤ ${max.replace('.', ',')}`;
    return `${min}-${max}`.replace(/\./g, ',');
};

const formatDimensions = (width, height) => {
    return `≤ ${parseInt(width, 10)} x ≤ ${parseInt(height, 10)}`;
};

const formatClassification = (classification, configuration) => {
    if (!classification) return '';
    return `EI ${classification}${configuration ? ' ' + configuration : ''}`;
};

const insulation_length = (item) => {
    return item.insulation_configuration_decoration 
  } 
  
const ProductSystemItem = ({ 
    title = "",
    imageUrl = "",
    onClick = () => {},
    productSystemId = 0,
    item = {},
    showDetails = false,
    accurancy = 0,
    isSelected,
}) => {
    imageUrl = URLS['BACKEND'] + imageUrl;

    const [showLocalDetails, setShowLocalDetails] = useState(showDetails());

    const toggleDetails = () => {
        
        if (isSelected) {
            setShowLocalDetails(!showLocalDetails);
        } else {
            onClick(productSystemId, item.id);
            setShowLocalDetails(true);
        }
    };

    const classRow = isSelected ? "product-item highlight-item" : "product-item";
    const hitAccuracy = `hit-accurancy-${accurancy}`;

    const colDiameter = item.medium_type === "lue_e" 
        ? formatDimensions(item.max_width_lueftung, item.max_height_lueftung)
        : formatRange(item.diameter_min, item.diameter_max);

    return (
        <>
            <tr className={`${classRow} ${hitAccuracy}`} onClick={toggleDetails}>
                <td className="penetration">{item.penetration_text}</td>
                <td className="diameter">{colDiameter}</td>
                <td className="thickness">{formatRange(item.thickness_min, item.thickness_max)}</td>
                <td className="insulation">{item.insulation_text}</td>
                <td className="insulation-thickness">{formatRange(item.insulation_strength_min, item.insulation_strength_max)}</td>
                <td className="insulation-length">{insulation_length(item)}</td>
                <td className="products">{item.products.map(x => x.title).join(", ")}</td>
                <td className="frc">{formatClassification(item.classification, item.tube_configuration)}</td>
                <td className="certification">{item.certification_reference}</td>
            </tr>
            {showLocalDetails && isSelected && <ProductSystemItemDetails id={ item.id } />}
        </>
    );
}

export default ProductSystemItem;
