import React from 'react';

const InitDataContext = React.createContext({
    item_01_pfv_uniqueID: "607e7a34-Ocfb-490d-934d-cccef4fe9bec-000609b9",
    item_02_type: "Horizontal",
    item_04_diameter: "140",
    item_05_height: "",
    item_06_width: "",
    item_07_length: "150",
    item_09_construction_element_uid: "607e7a34-Ocfb-490d-934d-cccef4fe9bec-000609b9",
    item_10_name: "IV-100-Leca",
    item_11_material_name: "N_LECA blockwork",
    item_12_material_category: "",
    item_13_material_class: "",
    item_14_fire_rating: "90",
    item_15_structural_asset: "2407",
    item_17_MEP_element_uniqueID: "607e7a34-Ocfb-490d-934d-cccef4fe9bec-000609b9",
    item_18_category: "Abwasser",
    item_19_description: "Geberit Silent-PP",
    item_20_material_code: "",
    item_mep_elements_size_height: "",
    item_23_width: "",
    item_24_diameter: "110",
    item_24_outer_diameter: "110",
    item_25_wall_thickness: "3.6",
    item_26_penetration_angle: "90",
    item_28_insulation_uid: "",
    item_30_description: "",
    item_31_national_code: "",
    item_32_material_code: "",
    item_33_coating_material_code: "",
    item_34_given_insulation_thickness: "",
    item_35_tec_assesment: "",
    item_direction_x: 0,
    item_direction_y: 0,
    item_direction_z: 0,
    item_location_x: -8103.6,
    item_location_y: 233301.0,
    item_location_z: 5975.0,
    item_mep_direction_x: 0,
    item_mep_direction_y: 0,
    item_mep_direction_z: 0,
    item_mep_location_x: -8103.6,
    item_mep_location_y: 23301.0,
    item_mep_location_z: 6000.0
})

export default InitDataContext;
