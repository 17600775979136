import React from 'react';

const DataButton = (props) => {

    const title = props.title;
    const currentGroupValue = props.currentGroupValue; 
    const value = props.value;
    const onSelect = props.onSelect; 
    const dataKey = props.dataKey;
    const initialValue = props.initialValue;
    const freezed = props.freezed || false;
    const styleType = props.widthClass || "";

    const onClickHandler = () => {
      return onSelect(dataKey, value);
    }
    
    const isClickable = () => ( onSelect !== undefined && !freezed )
    const isSelected = () => currentGroupValue === value
    const isInitialValue = () => (value !== initialValue)? "initval" : ""

    if (isSelected() && isClickable()) {
      return <button onClick={onClickHandler} className={"btn selected " + isInitialValue() + " " + styleType}>{title}</button> 

    } else if (!isSelected() && isClickable()) {
      return <button onClick={onClickHandler} className={"btn deselected " + styleType}>{title}</button> 

    } else if (!isSelected() && !isClickable()) {
      return <button className={"btn inactive freezed " + styleType}>{title}</button> 

    } else if (isSelected() && !isClickable()) {
      return <button className={"btn selected freezed " + styleType}>{title}</button> 
    }



}


export default DataButton;