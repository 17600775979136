import React from 'react';
import { useContext, useEffect, useState } from 'react';
import WizardContext from '../../../../store/wizard-context.js';
import DataContext from '../../../../store/data-context.js';
import InitDataContext from '../../../../store/init-data-context.js';
import ProductSystemItemContext from '../../../../store/product-system-item-context.js';
import ProductSystemContext from '../../../../store/product-system-context.js';
import './WizardStepOverviewSolution.css';
import Footer from '../../../Footer.js';
import { URLS } from '../../../../urls.js';
import * as utils from '../../../../utils.js';
import * as events from '../../../../actions/events.js';

const WizardStepOverviewSolution = () => {
    const wizardCtx = useContext(WizardContext);
    const dataCtx = useContext(DataContext);
    const initDataCtx = useContext(InitDataContext);
    const productSystemCtx = useContext(ProductSystemContext);
    const productSystemItemCtx = useContext(ProductSystemItemContext);
    const [selectedProductSystemItemId, setSelectedProductSystemItemId] = useState(undefined);

    const [calculationResults, setCalculationResults] = useState({});

    useEffect(() => {
        setSelectedProductSystemItemId(undefined);
        }, [wizardCtx.currentStep]);

    useEffect(() => {
      if (wizardCtx.currentStep === 'overview-solution') {
        console.log(dataCtx);
        const params = new URLSearchParams({ 
          product_system_item_id: productSystemItem.id,
          pfv_shape: dataCtx.item_custom_shape.current,
          mep_shape: dataCtx.item_custom_mep_shape.current,
          mep_element_diameter: dataCtx.item_24_diameter.current,
          mep_element_outer_diameter: dataCtx.item_24_outer_diameter.current,
          given_insulation_thickness: dataCtx.item_34_given_insulation_thickness.current,
          construction_element_thickness: initDataCtx.item_07_length,
          durchmesser_durchbruch: dataCtx.item_04_diameter.current,
          hoehe_durchbruch: dataCtx.item_05_height.current,
          breite_durchbruch: dataCtx.item_06_width.current,
          hoehe_mep_element: dataCtx.item_mep_elements_size_height.current,
          breite_mep_element: dataCtx.item_23_width.current,
          direction_x: dataCtx.custom_item_direction_x.current,
          direction_y: dataCtx.custom_item_direction_y.current,
          direction_z: dataCtx.custom_item_direction_z.current,
          location_x: dataCtx.custom_item_location_x.current,
          location_y: dataCtx.custom_item_location_y.current,
          location_z: dataCtx.custom_item_location_z.current,
          mep_direction_x: dataCtx.custom_item_mep_direction_x.current,
          mep_direction_y: dataCtx.custom_item_mep_direction_y.current,
          mep_direction_z: dataCtx.custom_item_mep_direction_z.current,
          mep_location_x: dataCtx.custom_item_mep_location_x.current,
          mep_location_y: dataCtx.custom_item_mep_location_y.current,
          mep_location_z: dataCtx.custom_item_mep_location_z.current
      });
      
        fetch(URLS['BACKEND'] + `/api/revit/calculate_consumptions_and_distances?${params}`)
        .then(response => response.json())
        .then(data => {
          setCalculationResults(data);
        }
        )
        .catch(
            error => {
                //setAnzTreffer(0);
            }
        );



        }
    }, [productSystemItemCtx.productSystemItem, , wizardCtx.currentStep]);

    if (wizardCtx.currentStep !== 'overview-solution'){
        return null;
      }

    // creates the data object to be sent to the plugin
    // or to be copied to the clipboard
    const createReturnData = () => {
      return (
        [
          {
            "UniqueId": dataCtx.item_01_pfv_uniqueID.current,
            "MepUniqueId": dataCtx.item_17_MEP_element_uniqueID.current,
            "Size": calculationResults["size"],
            "Parameters": calculationResults["sharded_params"]
          }
        ]
      )
    }
      
    const sendDataToPlugin = () => {
      const data = createReturnData();
      if (window.eoapi !== undefined) {
        let json = JSON.stringify(data);
        window.eoapi.extInvoke("MCPluginInsertProduct", json);
        events.sendPluginResultData(json)();
      }

    }


    const goToProductSystems = () => wizardCtx.goToProductSystems;
   
    const productSystem = productSystemCtx.productSystem;
    const productSystemItem = productSystemItemCtx.productSystemItem;


    const productDetail = (productSystem, productSystemItem) => {
        if (productSystemItem !== undefined) {
            return productSystemItem.certification_type + " " + productSystemItem.certification_number + " " + productSystemItem.certification_reference;
        }
        return "";
    }    

    const productUrl = (productSystem) => {
       if (productSystem !== undefined && productSystem.product_system_urls !== undefined) {
            const x = productSystem.product_system_urls.find(element => element.locale === "en" && element.topic === "website_hensel");
            if (x !== undefined) {
              return x.url;
            }
        }
    }

        
    // Outer width = Bei eckigem PfV: Feld 6 (WidthMm) + 200 mm (für Step1)
    const outerWidth = () => {
      if (dataCtx.item_custom_shape.current === "rectangle" && calculationResults["size"] !== undefined) {
        return parseInt(dataCtx.item_06_width.current) + 200;
        } else {
        return "-";
        }  
    }

    // Max. distance of the first support = Wert am Produktsystem > Maße & Werte > Abstände
    const maxDistanceOfFirstSupport = (productSystem) => {
        if (productSystem !== undefined && productSystem.spacing_first_support !== undefined) {
            return productSystem.spacing_first_support;
        } else {
            return "-";
        }   
    }

    const hasItems = (items) => {
        return (items !== undefined && items.length > 0);
    } 

    const tableClass = (productSystemItems) => {
        return (hasItems(productSystemItems) && productSystemItems[0])? "product-items " + productSystemItems[0].line_type : "product-items";
    }

    const copyToClipboard = text => {

      const data = createReturnData();
      const jData = JSON.stringify(data[0]);

      const tempInput = document.createElement('textarea');
      tempInput.style.position = 'absolute';
      tempInput.style.left = '-9999px';
      tempInput.value = jData;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand('copy');
      document.body.removeChild(tempInput);
    };

    const infoboxPfv = () => {
        if (dataCtx.item_custom_shape.current === "round" && dataCtx.item_custom_mep_shape.current === "round") {
            return infoboxRoundProvisionAndRoundMep();
        } else if (dataCtx.item_custom_shape.current === "rectangle" && dataCtx.item_custom_mep_shape.current === "rectangle") {
            return infoboxRectangleProvisionAndRectangleMep();
          } else if (dataCtx.item_custom_shape.current === "rectangle" && dataCtx.item_custom_mep_shape.current === "round") {
            return infoboxRectangleProvisionAndRoundMep();    
        } else {
            return (
              <dl>
                <dt>no data</dt>
                <dd>no data</dd>
              </dl>
            )
        }
    }

    const getPfvParameter = (parameter) => {
      return calculationResults["size"]?.[parameter] + " mm";
    }

    const infoboxRectangleProvisionAndRectangleMep = () => {
      return (
        <dl>
          <dt>PfV Inner Top Height</dt>
          <dd>{ getPfvParameter("HeightInnerTopMm") }</dd>
          <dt>PfV Inner Bottom Height</dt>
          <dd>{ getPfvParameter("HeightInnerBottomMm") }</dd>
          <dt>PfV Outer Top Height</dt>
          <dd>{ getPfvParameter("HeightOuterTopMm") }</dd>
          <dt>PfV Outer Bottom Height</dt>
          <dd>{ getPfvParameter("HeightOuterBottomMm") }</dd>
          <dt>PfV Length</dt>
          <dd>{ getPfvParameter("LengthMm") }</dd>
        </dl>
      ) 
    }

    const infoboxRoundProvisionAndRoundMep = () => {
        return (
          <dl>
            <dt>PfV Inner Diameter</dt>
            <dd>{ getPfvParameter("DiameterInnerMm") }</dd>
            <dt>PfV Outer Diameter</dt>
            <dd>{ getPfvParameter("DiameterOuterMm") }</dd>
            <dt>PfV Length</dt>
            <dd>{ getPfvParameter("LengthMm") }</dd>
          </dl>
        ) 
    }
   
    const infoboxRectangleProvisionAndRoundMep = () => {
      return (
        <dl>
          <dt>PfV Outer Top Height</dt>
          <dd>{ getPfvParameter("HeightOuterTopMm") }</dd>
          <dt>PfV Outer Bottom Height</dt>
          <dd>{ getPfvParameter("HeightOuterBottomMm") }</dd>
          <dt>PfV Length</dt>
          <dd>{ getPfvParameter("LengthMm") }</dd>
          <dt>PfV Outer Width</dt> 
          <dd>{ getPfvParameter("WidthOuterMm") }</dd>
          <dt>MEP inner Diameter</dt>
          <dd>{ getPfvParameter("DiameterInnerMm") }</dd>

        </dl>
      ) 
    }

    return (
    <span>
        <header>
            Transfer Solution to Autodesk Revit
        </header>
        <hr />
        <div className="sub-title">Data set to be transfered to Autodesk Revit</div>
        <p>Please check the following data again for correctness.</p>

        <dl>
            <dt>UniqueId</dt>
            <dd>{ dataCtx.item_01_pfv_uniqueID.current }</dd>
            <dt>Product name</dt>
            <dd>{ productSystem.title }</dd>
            <dt>Product Detail</dt>
            <dd>{ productDetail(productSystem, productSystemItem) }</dd>
            <dt>Product URL</dt>
            <dd>{ productUrl(productSystem) }</dd>
            <dt>MEP UniqueId</dt>
            <dd>{ dataCtx.item_17_MEP_element_uniqueID.current }</dd>
            <dt>Distance first support</dt>
            <dd>{ maxDistanceOfFirstSupport(productSystem) } mm</dd>
        </dl>

        { infoboxPfv() }

        <h4>Response from API</h4>
        <textarea rows="30"
        cols="90" value={JSON.stringify(calculationResults, null, 2)} readOnly><pre></pre></textarea>

        <hr /> 


        <Footer></Footer>
        
        <div className="navbar">
            <button className="back-btn" onClick={goToProductSystems()} style={{textDecoration: "none", color: "black"}} >{"<< Back to suitable product systems"}</button>
            <button className="continue-btn" onClick={copyToClipboard} style={{textDecoration: "none", color: "black"}}>{"Copy into Clipboard"}</button>
            <button className="continue-btn" onClick={sendDataToPlugin} style={{textDecoration: "none", color: "black"}}>{"Transfer Solution to Autodesk Revit >>"}</button>
        </div>
        
    </span>
    );
}

export default WizardStepOverviewSolution;