import React from 'react';

const Footer = () => {
    return (
      <footer>
        Copyright - Rudolf Hensel GmbH | Imprint | Contact
      </footer>
    )
}


export default Footer;