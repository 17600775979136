import axios from "axios";
import { URLS } from './urls';

// For common config
axios.defaults.headers.post["Content-Type"] = "application/json";

export const axiosProtected = axios.create({
    baseURL: URLS['BACKEND']
});

axiosProtected.interceptors.request.use(
   config => {
       const token = localStorage.getItem("token");
       if (token) {
           config.headers['x-access-token'] = 'Bearer ' + token;
       }
       // config.headers['Content-Type'] = 'application/json';
       return config;
   },
   error => {
       Promise.reject(error)
   });


export const axiosDownload = axios.create({
   baseURL: URLS['BACKEND'],
   responseType: 'blob'
});

axiosDownload.interceptors.request.use(
  config => {
      const token = localStorage.getItem("token");
      if (token) {
          config.headers['x-access-token'] = 'Bearer ' + token;
      }

      return config;
  },
  error => {
      Promise.reject(error)
  });



export const axiosPublic = axios.create({
    baseURL: URLS['BACKEND']
});


export const axiosTracker = axios.create({
    baseURL: URLS['BACKEND']
});

axiosTracker.interceptors.request.use(
  config => {
    config.headers['x-access-token'] = "Bearer asd9f87as9dfuzoasfh";
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  error => {
      Promise.reject(error)
  });
