import { useReducer } from 'react';

import SessionContext from './session-context.js';

const defaultSessionState = {
    username: "",
    token: ""
  }


const sessionReducer = (state, action) => {
  const updatedValue = action.value

  if (action.type === 'SET_token'){  
    return {
      ...state,
      token: updatedValue,
    }
  }
  return defaultSessionState;
}


const SessionProvider = props => {
    const [sessionState, dispatchDataAction] = useReducer(sessionReducer, defaultSessionState);
    
    const set_data_value = (name, value) => {
      if (name === 'token') {
        return dispatchDataAction({type: 'SET_token', value: value});
      } 
    };

    const sessionContext = {
      token: sessionState.token,
      set_data_value: set_data_value
    };

    return <SessionContext.Provider value={sessionContext}>
        {props.children}
    </SessionContext.Provider>
};

export default SessionProvider;