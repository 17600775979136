import React from 'react';

import WizardStepEditStart from './Steps/EditStart/WizardStepEditStart.js';
import WizardStepIntro from './Steps/Intro/WizardStepIntro.js';
import WizardStepReadjustment from './Steps/Readjustment/WizardStepReadjustment.js';
import WizardStepProductSystems from './Steps/ProductSystems/WizardStepProductSystems.js';
import WizardStepOverviewSolution from './Steps/OverviewSolution/WizardStepOverviewSolution.js';

import logoHensel from '../../logo-hensel-ps.png';
import logoMagiCAD from '../../magicad-logo.png';
import logoRevit from '../../revit-logo.png';


const Wizard = () => {


    return (
        <div className="content-area">
            <img src={logoHensel} alt="Hensel Logo" className="hensel-logo" />
            <img src={logoMagiCAD} alt="MagiCAD" className="magicad-logo" />
            <img src={logoRevit} alt="Revit" className="revit-logo" />
            
            <WizardStepEditStart />
            <WizardStepIntro />
            <WizardStepReadjustment />
            <WizardStepProductSystems />
            <WizardStepOverviewSolution />
            
        </div>
    )
}


export default Wizard;
