import React from 'react';

const ProductSystemContext = React.createContext({
    productSystems: [],
    productSystem: undefined,
    set_product_systems: (jsonData) => { return {asdf: null}}
})

export default ProductSystemContext;

