

export const toText = (token) => {
    const tokens = {
      abg: 'Germany (aBG)',
      eta: 'Europe (ETA)',

      wall: 'Wall',
      floor: 'Floor',

      massive: 'Rigid / Massive',
      drywall: 'Flexible / Drywall',
      wood: 'Wood / CLT',

      thickness_70: '≥ 70 mm',
      thickness_90: '≥ 90 mm',
      thickness_100: '≥ 100 mm',
      thickness_150: '≥ 150 mm',
      thickness_200: '≥ 200 mm',

    }
    return tokens[token];
}