import { useReducer } from 'react';

import ProductSystemItemContext from './product-system-item-context.js';

const defaulProductSystemItemsState = {
  productSystems: [],
  productSystemItem: {}
};

const productSystemItemReducer = (state, action) => {
  if (action.type === 'SET_PRODUCT_ITEMS'){
    const updatedData = action.data
    return {
      ...state,
      productSystemItems: updatedData,
    }
  }

  if (action.type === 'SET_PRODUCT_ITEM'){
    const updatedData = action.data
    return {
      ...state,
      productSystemItem: updatedData
    }
  }

  return defaulProductSystemItemsState;
};



const ProductSystemItemProvider = props => {
    const [wizardState, dispatchWizardAction] = useReducer(productSystemItemReducer, defaulProductSystemItemsState);

    const clear = () => {
      dispatchWizardAction({type: 'SET_PRODUCT_ITEMS', data: []});
    }
    
    const setProductSystemItems = (data) => {
      const arr = (data !== undefined && data['product_system_items'] !== undefined)? data['product_system_items'] : [];
      dispatchWizardAction({type: 'SET_PRODUCT_ITEMS', data: arr});
    };
    
    const setProductSystemItem = (data) => {
      dispatchWizardAction({type: 'SET_PRODUCT_ITEM', data: data});
    };


    const ProductSystemItemContextValue = {
      productSystemItems: wizardState.productSystemItems,
      productSystemItem: wizardState.productSystemItem,
      set_product_system_items: setProductSystemItems,
      set_product_system_item: setProductSystemItem,
      clear: clear,

    };

    return <ProductSystemItemContext.Provider value={ProductSystemItemContextValue}>
        {props.children}
    </ProductSystemItemContext.Provider>
};

export default ProductSystemItemProvider;