import React, { useContext, useEffect, useState } from 'react';
import WizardContext from '../../../../store/wizard-context.js';
import InitDataContext from '../../../../store/init-data-context.js';
import DataContext from '../../../../store/data-context.js';
import SessionContext from '../../../../store/session-context.js';
import './WizardStepIntro.css';
import Footer from '../../../Footer.js';
import * as events from '../../../../actions/events.js';
import { URLS } from '../../../../urls.js';
//import magicadConnector from '../../../../magicadConnector.js';

const WizardStepIntro = () => {
    const wizardCtx = useContext(WizardContext);
    const dataCtx = useContext(DataContext);
    const initDataCtx = useContext(InitDataContext);
    const sessionCtx = useContext(SessionContext);

    const [accessToken, setAccessToken] = useState(null);

    const RevitJson = (json) => {

        return {
            "UniqueId": json["UniqueId"],
            "Type": json["Type"],
            "Direction": {
                "X": json?.["Direction"]?.["X"],
                "Y": json?.["Direction"]?.["Y"],
                "Z": json?.["Direction"]?.["Z"],
            },
            "LocationMm": {
                "X": json?.["LocationMm"]?.["X"],
                "Y": json?.["LocationMm"]?.["Y"],
                "Z": json?.["LocationMm"]?.["Z"],
            },
            "Size": {
                "LengthMm": json?.["Size"]?.["LengthMm"],
                "HeightMm": json?.["Size"]?.["HeightMm"],
                "WidthMm": json?.["Size"]?.["WidthMm"],
                "DiameterMm": json?.["Size"]?.["DiameterMm"],
            },
            "StructuralElements": [{ 
                "UniqueId": json?.["StructuralElements"][0]?.["UniqueId"],
                "Name": json?.["StructuralElements"][0]?.["Name"],
                "MaterialName": json?.["StructuralElements"][0]?.["MaterialName"],
                "MaterialCategory": json?.["StructuralElements"][0]?.["MaterialCategory"],
                "MaterialClass": json?.["StructuralElements"][0]?.["MaterialClass"],
                "FireRating": json?.["StructuralElements"][0]?.["FireRating"],
                "StructuralAsset": {
                    "DensityKgM3": json?.["StructuralElements"][0]?.["StructuralAsset"]?.["DensityKgM3"],
                },
            }],
            "MepElements": [{
                "UniqueId": json?.["MepElements"][0]?.["UniqueId"],
                "Category": json?.["MepElements"][0]?.["Category"],
                "Description": json?.["MepElements"][0]?.["Description"],
                "MaterialCode": json?.["MepElements"][0]?.["MaterialCode"],
                "Direction": {
                    "X": json?.["MepElements"][0]?.["Direction"]?.["X"],
                    "Y": json?.["MepElements"][0]?.["Direction"]?.["Y"],
                    "Z": json?.["MepElements"][0]?.["Direction"]?.["Z"],
                },
                "LocationMm": {
                    "X": json?.["MepElements"][0]?.["LocationMm"]?.["X"],
                    "Y": json?.["MepElements"][0]?.["LocationMm"]?.["Y"],
                    "Z": json?.["MepElements"][0]?.["LocationMm"]?.["Z"],
                },
                "Size": {
                    "DiameterMm": json?.["MepElements"][0]?.["Size"]?.["DiameterMm"],
                    "OuterDiameterMm": json?.["MepElements"][0]?.["Size"]?.["OuterDiameterMm"],
                    "HeightMm": json?.["MepElements"][0]?.["Size"]?.["HeightMm"],
                    "WidthMm": json?.["MepElements"][0]?.["Size"]?.["WidthMm"],   
                    "WallThicknessMm": json?.["MepElements"][0]?.["Size"]?.["WallThicknessMm"],
                },
                "AngleDeg": json?.["MepElements"][0]?.["AngleDeg"],   
                "Insulation": {
                    "UniqueId": json?.["MepElements"][0]?.["Insulation"]?.["UniqueId"],   
                    "Code": json?.["MepElements"][0]?.["Insulation"]?.["Code"],
                    "Description": json?.["MepElements"][0]?.["Insulation"]?.["Description"],
                    "NationalCode": json?.["MepElements"][0]?.["Insulation"]?.["NationalCode"],
                    "MaterialCode": json?.["MepElements"][0]?.["Insulation"]?.["MaterialCode"],
                    "CoatingMaterialCode": json?.["MepElements"][0]?.["Insulation"]?.["CoatingMaterialCode"],
                    "ThicknessMm": json?.["MepElements"][0]?.["Insulation"]?.["ThicknessMm"],
                }
            }]
        }
    }
      
    const magicadConnector = {
        WebAppInsertProduct: function(arg1, arg2, token, originalJson) {
            try {
                setAccessToken(token);
                var jsonArray = JSON.parse(originalJson);
                var json = jsonArray[0];
                var StructuralElements = RevitJson(json)["StructuralElements"][0];
                var MepElements = RevitJson(json)["MepElements"][0];
                initDataCtx.set_data_value('item_35_tec_assesment', "");

                initDataCtx.set_data_value('item_09_construction_element_uid', StructuralElements["UniqueId"]);
                initDataCtx.set_data_value('item_15_structural_asset', StructuralElements["StructuralAsset"]["DensityKgM3"]);
                initDataCtx.set_data_value('item_10_name', StructuralElements["Name"]);
                initDataCtx.set_data_value('item_11_material_name', StructuralElements["MaterialName"]);
                initDataCtx.set_data_value('item_12_material_category', StructuralElements["MaterialCategory"]);
                initDataCtx.set_data_value('item_13_material_class', StructuralElements["MaterialClass"]);
                initDataCtx.set_data_value('item_14_fire_rating', StructuralElements["FireRating"]);

                initDataCtx.set_data_value('item_02_type', RevitJson(json)["Type"]);
                initDataCtx.set_data_value('item_01_pfv_uniqueID', RevitJson(json)["UniqueId"]);

                initDataCtx.set_data_value('item_04_diameter', RevitJson(json)["Size"]["DiameterMm"]);
                initDataCtx.set_data_value('item_05_height', RevitJson(json)["Size"]["HeightMm"]);
                initDataCtx.set_data_value('item_06_width', RevitJson(json)["Size"]["WidthMm"]);
                initDataCtx.set_data_value('item_07_length', RevitJson(json)["Size"]["LengthMm"]);

                initDataCtx.set_data_value('item_17_MEP_element_uniqueID', MepElements["UniqueId"]);
                initDataCtx.set_data_value('item_18_category', MepElements["Category"]);
                initDataCtx.set_data_value('item_19_description', MepElements["Description"]);
                initDataCtx.set_data_value('item_20_material_code', MepElements["MaterialCode"]);
                initDataCtx.set_data_value('item_24_diameter', MepElements["Size"]["DiameterMm"]);
                initDataCtx.set_data_value('item_24_outer_diameter', MepElements["Size"]["OuterDiameterMm"]);
                initDataCtx.set_data_value('item_mep_elements_size_height', MepElements["Size"]["HeightMm"]); 
                initDataCtx.set_data_value('item_23_width', MepElements["Size"]["WidthMm"]);
                initDataCtx.set_data_value('item_25_wall_thickness', MepElements["Size"]["WallThicknessMm"]);
                initDataCtx.set_data_value('item_26_penetration_angle', MepElements["AngleDeg"]);
                initDataCtx.set_data_value('item_28_insulation_uid', MepElements["Insulation"]["UniqueId"]);
                initDataCtx.set_data_value('item_30_description', MepElements["Insulation"]["Description"]);
                initDataCtx.set_data_value('item_31_national_code', MepElements["Insulation"]["NationalCode"]);
                initDataCtx.set_data_value('item_32_material_code', MepElements["Insulation"]["MaterialCode"]);
                initDataCtx.set_data_value('item_33_coating_material_code', MepElements["Insulation"]["CoatingMaterialCode"]);
                initDataCtx.set_data_value('item_34_given_insulation_thickness', MepElements["Insulation"]["ThicknessMm"]);

                initDataCtx.set_data_value('item_direction_x', RevitJson(json)["Direction"]["X"]);
                initDataCtx.set_data_value('item_direction_y', RevitJson(json)["Direction"]["Y"]);
                initDataCtx.set_data_value('item_direction_z', RevitJson(json)["Direction"]["Z"]);

                initDataCtx.set_data_value('item_location_x', RevitJson(json)["LocationMm"]["X"]);
                initDataCtx.set_data_value('item_location_y', RevitJson(json)["LocationMm"]["Y"]);
                initDataCtx.set_data_value('item_location_z', RevitJson(json)["LocationMm"]["Z"]);

                initDataCtx.set_data_value('item_mep_direction_x', MepElements["Direction"]["X"]);
                initDataCtx.set_data_value('item_mep_direction_y', MepElements["Direction"]["Y"]);
                initDataCtx.set_data_value('item_mep_direction_z', MepElements["Direction"]["Z"]);

                initDataCtx.set_data_value('item_mep_location_x', MepElements["LocationMm"]["X"]);
                initDataCtx.set_data_value('item_mep_location_y', MepElements["LocationMm"]["Y"]);
                initDataCtx.set_data_value('item_mep_location_z', MepElements["LocationMm"]["Z"]);

                events.fetchPluginInitalData(originalJson)();
            } catch (e) {
                alert('invalid json x');
            }

        }
      };

    useEffect(() => {
       if (window.eoapi !== undefined) {
            window.eoapi.extInvoke("MCPluginWebAppLoaded", magicadConnector);
            
            // window.eoapi.extInvoke("MCPluginInsertProduct", {});
       } else {
            console.log('Magicad not loaded');
         }
    }, []);


    if (wizardCtx.currentStep !== 'intro'){
        return null;
      }

    const verifyToken = () => {
        fetch(URLS['BACKEND'] + `/api/revit/verifyToken?${accessToken}`)
        .then(response => console.log(response))
        .catch(
            error => {
            }
        );
    }

    const goToReadjustment = () => {
        dataCtx.doDataMapping(
            {
                custom_item_01_pfv_uniqueID: initDataCtx.item_01_pfv_uniqueID,
                custom_item_02_type: initDataCtx.item_02_type,
                custom_item_04_diameter: initDataCtx.item_04_diameter,
                custom_item_05_height: initDataCtx.item_05_height,
                custom_item_06_width: initDataCtx.item_06_width,
                custom_item_07_length: initDataCtx.item_07_length,
                custom_item_09_construction_element_uid: initDataCtx.item_09_construction_element_uid,
                custom_item_10_name: initDataCtx.item_10_name,
                custom_item_11_material_name: initDataCtx.item_11_material_name,
                custom_item_12_material_category: initDataCtx.item_12_material_category,
                custom_item_13_material_class: initDataCtx.item_13_material_class,
                custom_item_14_fire_rating: initDataCtx.item_14_fire_rating,
                custom_item_15_structural_asset: initDataCtx.item_15_structural_asset,
                custom_item_17_MEP_element_uniqueID: initDataCtx.item_17_MEP_element_uniqueID,
                custom_item_18_category: initDataCtx.item_18_category,
                custom_item_19_description: initDataCtx.item_19_description,
                custom_item_20_material_code: initDataCtx.item_20_material_code,
                custom_item_mep_elements_size_height: initDataCtx.item_mep_elements_size_height,
                custom_item_23_width: initDataCtx.item_23_width,
                custom_item_24_diameter: initDataCtx.item_24_diameter,
                custom_item_24_outer_diameter: initDataCtx.item_24_outer_diameter,
                custom_item_25_wall_thickness: initDataCtx.item_25_wall_thickness,
                custom_item_26_penetration_angle: initDataCtx.item_26_penetration_angle,
                custom_item_28_insulation_uid: initDataCtx.item_28_insulation_uid,
                custom_item_34_given_insulation_thickness: initDataCtx.item_34_given_insulation_thickness,
                custom_item_35_tec_assesment: initDataCtx.item_35_tec_assesment,
                custom_item_custom_penetrating_service_type: initDataCtx.item_custom_penetrating_service_type,
                custom_item_09_construction_element_uid: initDataCtx.item_09_construction_element_uid,
                custom_item_28_insulation_uid: initDataCtx.item_28_insulation_uid,
                custom_item_09_construction_element_uid: initDataCtx.item_09_construction_element_uid,

                custom_item_direction_x: initDataCtx.item_direction_x,
                custom_item_direction_y: initDataCtx.item_direction_y,
                custom_item_direction_z: initDataCtx.item_direction_z,

                custom_item_location_x: initDataCtx.item_location_x,
                custom_item_location_y: initDataCtx.item_location_y,
                custom_item_location_z: initDataCtx.item_location_z,

                custom_item_mep_direction_x: initDataCtx.item_mep_direction_x,
                custom_item_mep_direction_y: initDataCtx.item_mep_direction_y,
                custom_item_mep_direction_z: initDataCtx.item_mep_direction_z,

                custom_item_mep_location_x: initDataCtx.item_mep_location_x,
                custom_item_mep_location_y: initDataCtx.item_mep_location_y,
                custom_item_mep_location_z: initDataCtx.item_mep_location_z,

                compute_insulation_material: {uid: initDataCtx.item_28_insulation_uid, 
                                              description: initDataCtx.item_30_description, 
                                              national_code: initDataCtx.item_31_national_code, 
                                              material_code: initDataCtx.item_32_material_code, 
                                              coating_material_code: initDataCtx.item_33_coating_material_code},


            }
        );
        return wizardCtx.goToReadjustment(); 
    }

    const goToEditStart = () => wizardCtx.goToEditStart;

    return (
        <span>
        <header>
            Transmitted data set for Hensel product selection
        </header>
        <hr />

        <div className="container">
            <div className="table-title">General Requirements</div>

            <table className="page1">
                <tbody>
                    <tr>
                        <td>Technical Assesment:</td>
                        <td>{ initDataCtx.item_35_tec_assesment }</td>
                        <td>(35 TecAssesment)</td>
                    </tr>
                </tbody>
            </table>
            
            <div className="table-title">Construction Element</div>
            
            <table className="page1">
                <tbody>
                    <tr>
                        <td>Construction Element UniqueID:</td>
                        <td>{ initDataCtx.item_09_construction_element_uid}</td>
                        <td>(9 UniqueID)</td>
                    </tr>
                    <tr>
                        <td>Construction Element:</td>
                        <td>{ initDataCtx.item_02_type }</td>
                        <td>(2 Type)</td>
                    </tr>
                    <tr>
                        <td>Density [kg/m3]:</td>
                        <td>{ initDataCtx.item_15_structural_asset }</td>
                        <td>(15 Structural/Asset)</td>
                    </tr>
                    <tr>
                        <td>Material:</td>
                        <td>{ initDataCtx.item_10_name }</td>
                        <td>(10 Name)</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>{ initDataCtx.item_11_material_name }</td>
                        <td>(11 Material/Name)</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>{ initDataCtx.item_12_material_category }</td>
                        <td>(12 Material/Category)</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>{ initDataCtx.item_13_material_class }</td>
                        <td>(13 Material/Class)</td>
                    </tr>
                    <tr>
                        <td>Thickness [mm]</td>
                        <td>{ initDataCtx.item_07_length }</td>
                        <td>(7 LengthMm)</td>
                    </tr>
                    <tr>
                        <td>Required fire resistance period [min]</td>
                        <td>{ initDataCtx.item_14_fire_rating }</td>
                        <td>(14 FireRating)</td>
                    </tr>
                </tbody>
            </table>

            <div className="table-title">Provision for Void (PfV)</div>

            <table className="page1">
                <tbody>
                    <tr>
                        <td>UniqueID:</td>
                        <td>{ initDataCtx.item_01_pfv_uniqueID }</td>
                        <td>(1 UniqueID)</td>
                    </tr>
                    <tr>
                        <td>Diameter [mm]:</td>
                        <td>{ initDataCtx.item_04_diameter }</td>
                        <td>(4 DiameterMm)</td>
                    </tr>
                    
                    <tr>
                        <td>Height [mm]:</td>
                        <td>{ initDataCtx.item_05_height }</td>
                        <td>(5 HeightMm)</td>
                    </tr>
                    <tr>
                        <td>Width [mm]:</td>
                        <td>{ initDataCtx.item_06_width }</td>
                        <td>(6 WidthMm)</td>
                    </tr>
                    <tr>
                        <td>Lenght [mm]:</td>
                        <td>{ initDataCtx.item_07_length }</td>
                        <td>(7 LenghtMm)</td>
                    </tr>
                </tbody>
            </table>

            <div className="table-title">MEP Element</div>
            <table className="page1">
                <tbody>
                    <tr>
                        <td>UniqueID:</td>
                        <td>{ initDataCtx.item_17_MEP_element_uniqueID }</td>
                        <td>(17 UniqueID)</td>
                    </tr>
                    <tr>
                        <td>Diameter [mm]:</td>
                        <td>{ initDataCtx.item_24_diameter }</td>
                        <td>(24 DiameterMm)</td>
                    </tr>
                    <tr>
                        <td>Outer Diameter [mm]:</td>
                        <td>{ initDataCtx.item_24_outer_diameter }</td>
                        <td>(24 OuterDiameterMm)</td>
                    </tr> 
                    <tr>
                        <td>Height [mm]:</td>
                        <td>{ initDataCtx.item_mep_elements_size_height }</td>
                        <td>(22 HeightMm)</td>
                    </tr>
                    <tr>
                        <td>Width [mm]:</td>
                        <td>{ initDataCtx.item_23_width }</td>
                        <td>(23 WidthMm)</td>
                    </tr>
                    <tr>
                        <td>Wall Thickness [mm]:</td>
                        <td>{ initDataCtx.item_25_wall_thickness }</td>
                        <td>(25 WallThicknessMm)</td>
                    </tr>
                    <tr>
                        <td>Penetration angle [deg]:</td>
                        <td>{ initDataCtx.item_26_penetration_angle }</td>
                        <td>(26 Penetration Angle)</td>
                    </tr>
                    <tr>
                        <td>Category:</td>
                        <td>{ initDataCtx.item_18_category }</td>
                        <td>(18 Category)</td>
                    </tr>
                    <tr>
                        <td>Description:</td>
                        <td>{ initDataCtx.item_19_description }</td>
                        <td>(19 Description)</td>
                    </tr>
                    <tr>
                        <td>MaterialCode:</td>
                        <td>{ initDataCtx.item_20_material_code }</td>
                        <td>(20 MaterialCode)</td>
                    </tr>
                </tbody>                     
            </table>

            <div className="table-title">Insulation</div>
            <table className="page1">
                <tbody>
                    <tr>
                        <td>UniqueID:</td>
                        <td>{ initDataCtx.item_28_insulation_uid }</td>
                        <td>(28 UniqueID)</td>
                    </tr>
                    <tr>
                        <td>Description:</td>
                        <td>{ initDataCtx.item_30_description }</td>
                        <td>(30 Description)</td>
                    </tr>
                    <tr>
                        <td>NationalCode:</td>
                        <td>{ initDataCtx.item_31_national_code }</td>
                        <td>(31 NationalCode)</td>
                    </tr>
                    <tr>
                        <td>MaterialCode:</td>
                        <td>{ initDataCtx.item_32_material_code }</td>
                        <td>(32 MaterialCode)</td>
                    </tr>
                    <tr>
                        <td>CoatingMaterialCode:</td>
                        <td>{ initDataCtx.item_33_coating_material_code }</td>
                        <td>(33 CoatingMaterialCode)</td>
                    </tr>
                    <tr>
                        <td>Thickness:</td>
                        <td>{ initDataCtx.item_34_given_insulation_thickness }</td>
                        <td>(34 ThicknessMm)</td>
                    </tr>
                </tbody>                 
            </table>

            <Footer></Footer>

            
            <div className="navbar">
              <button className="continue-btn" onClick={goToEditStart()} style={{textDecoration: "none", color: "black"}}>{"<< Edit Startvalues"}</button>
              <button className="continue-btn" onClick={goToReadjustment} style={{textDecoration: "none", color: "black"}}>{"Import data >>"}</button>
            </div>
          </div>
        </span>
    );
}

export default WizardStepIntro;