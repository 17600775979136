import { useReducer } from 'react';

import WizardContext from './wizard-context.js';

const defaultWizardState = {
  currentStep: 'intro'
};

const wizardReducer = (state, action) => {
  if (action.type === 'SET_STEP'){
    const updatedStep = action.step
    return {
      currentStep: updatedStep
    }
  }
  return defaultWizardState;
};

const WizardProvider = props => {
    const [wizardState, dispatchWizardAction] = useReducer(wizardReducer, defaultWizardState);

    const setCurrentToNextStep = (nextStep) => {
      dispatchWizardAction({type: 'SET_STEP', step: nextStep});
    };
    
    const goToEditStart = () => { setCurrentToNextStep('editstart') };
    const goToIntro = () => { setCurrentToNextStep('intro') };
    const goToReadjustment = () => { setCurrentToNextStep('readjustment') };
    const goToProductSystems = () => { setCurrentToNextStep('productsystems') };
    const goToOverviewSolution = () => { setCurrentToNextStep('overview-solution') };

    const wizardContext = {
      currentStep: wizardState.currentStep,
      setCurrentStep: setCurrentToNextStep,
      goToEditStart: goToEditStart,
      goToIntro: goToIntro,
      goToReadjustment: goToReadjustment,
      goToProductSystems: goToProductSystems,
      goToOverviewSolution: goToOverviewSolution,
    };

    return <WizardContext.Provider value={wizardContext}>
        {props.children}
    </WizardContext.Provider>
};

export default WizardProvider;